export const DAMAGE = "DAMAGE";
export const OPTION = "OPTION";
export const DECLARATION = "DECLARATION";
export const AUCTION_EXPRESS_COLUMN_NAME = "AUCTION_EXPRESS_COLUMN_NAME";

export const LABEL_CATEGORIES = {
  [DAMAGE]: "damage",
  [OPTION]: "option",
  [DECLARATION]: "declaration",
  [AUCTION_EXPRESS_COLUMN_NAME]: "auction_express_column_name",
};
