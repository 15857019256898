<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ label.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_LABELS)"
            @click="deleteLabel"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_LABELS)"
            @click="editLabel"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("LABELS.CATEGORY") }}</dt>
          <dd class="text-uppercase" v-if="label?.category">
            {{ $t("LABELS." + label?.category.toUpperCase()) }}
          </dd>
        </dl>

        <dl class="row" v-if="label.slug">
          <dt>{{ $t("LABELS.KEY") }}</dt>
          <dd>
            {{ label.slug ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="label.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ label.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="label.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ label.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(label.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(label.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "label-view-global",

  props: ["label"],

  data() {
    return {};
  },

  created() {},

  methods: {
    labelUpdated() {
      this.$emit("labelUpdated", true);
    },
    editLabel() {
      this.$emit("onEditLabel", this.label);
    },
    deleteLabel() {
      this.$emit("onDeleteLabel", this.label);
    },
  },

  mounted() {},

  watch: {
    label(label) {},
  },
};
</script>
